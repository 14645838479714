import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./Landing";
import Terms from "./pages/Terms";
import WithNav from "./layouts/WithNav";
import Privacy from "./pages/Privacy";
import Disclaimer from "./pages/Disclaimer";
import Changelog from "./pages/Changelog/Changelog";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<WithNav />}>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/disclaimer" element={<Disclaimer />}></Route>
          <Route path="/changelog" element={<Changelog />}></Route>
          <Route path="*" element={<Landing />}></Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
