import "./App.css";
import Home from "./pages/home";

import "../src/assets/fonts/GeneralSans-Regular.ttf";
import "../src/assets/fonts/GeneralSans-Medium.ttf";
import "../src/assets/fonts/GeneralSans-Light.ttf";
import "../src/assets/fonts/GeneralSans-Extralight.ttf";
import "../src/assets/fonts/GeneralSans-Semibold.ttf";
import "../src/assets/fonts/GeneralSans-Bold.ttf";
import Join from "./pages/Join";
import { useEffect, useState } from "react";
import Integrations from "./pages/Integrations";
import Testimonials from "./pages/Testimonials";
import FAQPage from "./pages/FAQPage";
import FeaturePageOne from "./pages/FeaturePageOne";
import FeaturePageTwo from "./pages/FeaturePageTwo";
import FeaturePageThree from "./pages/FeaturePageThree";
import Pricing from "./pages/Pricing";
import FeaturePageFour from "./pages/FeaturePageFour";
import Navbar from "./components/Navbar";
import { useLocation } from "react-router-dom";

function Landing() {
  const [alert, setAlert] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToId) {
      const el = document.getElementById(location.state.scrollToId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.state]);

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAlert(false);
  };

  return (
    <div className="app">
      {alert && (
        <div className="alert-wrapper" onClick={handleClose}>
          <div className="alert-container">
            <p className="alert-txt">
              An error occurred while joining the waitlist!
            </p>
            <button className="close-btn" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      )}
      <Navbar />
      <div id="home" className="home-wrapper">
        <Home setAlert={setAlert} alert={alert} />
      </div>
      <div id="features" className="features-wrapper">
        <Integrations />
      </div>
      <FeaturePageFour />
      <FeaturePageOne />
      <FeaturePageTwo />
      <FeaturePageThree />
      <Testimonials />
      <div id="pricing" className="pricing-wrapper">
        <Pricing />
      </div>
      <div id="faq" className="faqpage-wrapper">
        <FAQPage />
      </div>
      <div id="join" className="join-wrapper">
        <Join setAlert={setAlert} alert={alert} />
      </div>
    </div>
  );
}

export default Landing;
