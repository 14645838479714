import React from "react";
import "./Pricing.css";
import Checkmark from "../components/Checkmark";
import { motion } from "framer-motion";

function Pricing() {
  const handleScroll = () => {
    const el = document.getElementById("join");
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };
  //test

  return (
    <div className="pricing-container">
      <div className="test-title-wrapper">
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.25 }}
          className="features-txt"
        >
          Choose the perfect plan for your success.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
          className="features-sub"
        >
          Accessible pricing for all education levels - pause or cancel anytime.
        </motion.p>
      </div>
      <div className="pricing-boxes-wrapper">
        <div className="p-top-wrapper">
          <motion.div
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.6 }}
            className="pricing-box"
          >
            <p className="pricing-box-title">Starter</p>
            <p className="pricing-box-sub">Free, forever</p>
            <p className="price">Free</p>
            <div className="requirement">
              <Checkmark />
              <p className="requirement-txt">1GB Storage</p>
            </div>
            <div className="requirement">
              <Checkmark />
              <p className="requirement-txt">Course Management</p>
            </div>
            <div className="requirement">
              <Checkmark />
              <p className="requirement-txt">Task Management</p>
            </div>
            <div className="requirement">
              <Checkmark />
              <p className="requirement-txt">Single File Upload up to 50MB</p>
            </div>
            <div className="requirement">
              <Checkmark />
              <p className="requirement-txt">2 AI Flashcard sets per month</p>
            </div>
            <p className="and">And more...</p>
            <div className="p-space"></div>
            <button className="buy-btn" onClick={handleScroll}>
              Request Invite
            </button>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.8 }}
            className="pricing-box"
          >
            <p className="pricing-box-title">Pro</p>
            <p className="pricing-box-sub">Pause or cancel anytime</p>
            <p className="price">$12/mo</p>
            <p className="pro-txt">Everything in Starter, plus:</p>
            <div className="requirement">
              <Checkmark />
              <p className="requirement-txt">50GB Storage</p>
            </div>
            <div className="requirement">
              <Checkmark />
              <p className="requirement-txt">Single File Upload up to 250MB</p>
            </div>
            <div className="requirement">
              <Checkmark />
              <p className="requirement-txt">AI Calendar scheduling</p>
            </div>
            <div className="requirement">
              <Checkmark />
              <p className="requirement-txt">Slides to notes generator</p>
            </div>
            <div className="requirement">
              <Checkmark />
              <p className="requirement-txt">Offline access</p>
            </div>
            <p className="and">And more to come...</p>
            <div className="p-space"></div>
            <button className="buy-btn" onClick={handleScroll}>
              Request Invite
            </button>
          </motion.div>
        </div>
        <div className="p-bottom-wrapper">
          <motion.div
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.6 }}
            className="limited-offer-box"
          >
            <div className="limited-left">
              <p className="limited-txt">LIMITED OFFER</p>
              <p className="pricing-box-title life">
                Founders Club (Lifetime access)
              </p>
              <div className="p-space"></div>
              <p className="limited-content">
                We're offering a limited opportunity to join our exclusive
                founders club which will get you lifetime access to Notibly Pro
                (normally $12/month). As a Founders Club member, you'll be the
                first to experience and shape new features, with direct input
                into what we build next, and much more.
              </p>
            </div>
            <div className="limited-right">
              <p className="limited-price">
                $50<span className="smaller">/LIFE</span>
              </p>

              <p className="spots-left">10 left</p>
              <div className="p-space"></div>
              <button
                className="reserve-btn"
                onClick={() =>
                  window.open(
                    "https://buy.stripe.com/9AQcN8ejraiP8koaEH",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Reserve Spot
              </button>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
