import React from "react";
import "./Changelog.css";

import Date20240926 from "./Dates/2024-09-26";
import Date20240915 from "./Dates/2024-09-15";
import Date20240908 from "./Dates/2024-09-08";
import Date20240831 from "./Dates/2024-08-31";
import Date20241014 from "./Dates/2024-10-14";
import Date20241104 from "./Dates/2024-11-04";
import Date20241117 from "./Dates/2024-11-17";

function Changelog() {
  return (
    <div className="changelog-wrapper">
      <h1 className="changelog-title">Changelog</h1>
      <p className="changelog-sub">
        Here you can find the latest improvements to Notibly.
      </p>
      <Date20241117 />
      <Date20241104 />
      <Date20241014 />
      <Date20240926 />
      <Date20240915 />
      <Date20240908 />
      <Date20240831 />
    </div>
  );
}

export default Changelog;
