function Date20240808() {
  return (
    <div className="changelog-container" id="2024-Sep-8">
      <div className="changelog-date">
        <p>September 8th 2024</p>
      </div>
      <div className="changelog-content">
        <h1 className="changelog-big-title"> Post-alpha fixes (v0.1.1) </h1>
        <h1 className="changelog-mini-title"> General fixes </h1>
        <ul className="changelog-content-text list-margin">
          <li>Fixed authentication flow to support all mail clients</li>
          <li>Minor calendar style adjustments</li>
          <li>Improved adding of assignments (Now can press enter)</li>
          <li>Fixed overflow issues with notes/folders on notes page</li>
          <li>Hover text added throughout the app for button clarity</li>
          <li>
            Fixed assignments spanning across 2 days when due date time set to
            11:59pm
          </li>
          <li>
            Style of buttons and icons more consistent with long assignment or
            course names
          </li>
        </ul>
        <h1 className="changelog-mini-title"> Calendar improvements </h1>
        <ul className="changelog-content-text list-margin">
          <li>Added ability to duplicate and edit events</li>
          <li>Course information added to calendar event and popover</li>
          <li>Added ability to drag to create events</li>
          <li>
            When selecting “weekends” they now appear after Friday making the
            first day of the week Monday (This will later be able to be
            controlled in settings)
          </li>
        </ul>

        <h1 className="changelog-mini-title"> Assignment improvements </h1>
        <ul className="changelog-content-text list-margin">
          <li>New “Done” option to view completed Assignments</li>
          <li>File uploading support added (Still testing)</li>
          <li>
            Support added to upload files/documents alongside your notes.
            (Download and open only for now, later we will add an in-app
            document viewer)
          </li>
          <li>
            Each user is limited to 1GB of space, until pro plans are released
            which will give you 50GB
          </li>
          <li>50MB Max file size upload (At a time)</li>
        </ul>

        <h1 className="changelog-mini-title"> What’s next? </h1>
        <p className="changelog-content-text">
          Going forward, we are aiming for one update per week, with one major
          update once every 3-4 weeks. Here’s a few things that are next on our
          todo list:
        </p>
        <ul className="changelog-content-text list-margin">
          <ul>
            <li>Continue improving calendar UX</li>
            <li>Improvements of the Agenda page (Drag and drop timeboxing)</li>
            <li>
              Individual assignment page (Add links, files, notes etc. within an
              individual assignment page to keep things organized)
            </li>
          </ul>
        </ul>
      </div>
    </div>
  );
}

export default Date20240808;
