import React from "react";
import "./Integrations.css";

import app1 from "../assets/app1.svg";
import app2 from "../assets/app2.svg";
import app3 from "../assets/app3.svg";
import app4 from "../assets/app4.svg";
import app5 from "../assets/app5.svg";
import app6 from "../assets/app6.svg";

import { motion } from "framer-motion";

function Integrations() {
  return (
    <div className="integrations-wrapper">
      <motion.p
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.25 }}
        className="features-txt"
      >
        Unite your academic arsenal.
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.5 }}
        className="integration-container"
      >
        <div className="int-left">
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.5 }}
            className="int-top-txt"
          >
            INTEGRATIONS
          </motion.p>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.6 }}
            className="int-heading"
          >
            Organize your studies on a single platform.
          </motion.p>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.7 }}
            className="int-sub-txt"
          >
            Stop juggling multiple apps. Start mastering them all in one
            seamless experience with Notibly.
          </motion.p>
        </div>
        <div className="int-right">
          <div className="row r-one">
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.5 }}
              className="int"
            >
              <img src={app6} alt="" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.6 }}
              className="int"
            >
              <img src={app2} alt="" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.7 }}
              className="int"
            >
              <img src={app3} alt="" />
            </motion.div>
          </div>
          <div className="row">
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.8 }}
              className="int"
            >
              <img src={app4} alt="" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.9 }}
              className="int"
            >
              <img src={app5} alt="" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 1 }}
              className="int"
            >
              <img src={app1} alt="" />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Integrations;
