import React from "react";

function Date20240825() {
  return (
    <div className="changelog-container" id="2024-Sep-15">
      <div className="changelog-date">
        <p>September 26th, 2024</p>
      </div>
      <div className="changelog-content">
        <h1 className="changelog-big-title">
          Latest Updates and Improvements (v0.2.1)
        </h1>
        <h1 className="changelog-mini-title">What’s new</h1>
        <p className="changelog-content-text ">
          Resizeable left navigation menu
        </p>

        <h1 className="changelog-mini-title">User settings and preferences</h1>
        <p className="changelog-content-text ">
          Additional customized preferences
        </p>

        <h1 className="changelog-mini-title">Agenda</h1>
        <ul className="changelog-content-text list-margin">
          <li>Unlimited tasks can be added (previous was 7 per day)</li>
          <li>"Jump to today" button added</li>
        </ul>

        <h1 className="changelog-mini-title">Flashcards</h1>

        <ul className="changelog-content-text list-margin">
          <li>Always show the front card first</li>
          <li>Show percent on deck card at /flashcards</li>
          <li>Other minor UI/UX improvements</li>
        </ul>

        <h1 className="changelog-mini-title">General fixes and improvements</h1>
        <ul className="changelog-content-text list-margin">
          <li>Calendar state of buttons saves more often</li>
          <li>Go to the assignment page from the calendar page</li>
          <li>Better ordering of assignments</li>
          <li>Consistent icons and input fields</li>
          <li>
            Duplicate events now act as their own event apart from a group
          </li>
          <li>Improved display of calendar events on the Agenda page</li>
          <li>Improved display of assignments on the Course page</li>
          <li>
            Course code is now alongside the name of the course on some pages
          </li>
          <li>Backend/frontend improvements</li>
        </ul>

        <h1 className="changelog-mini-title">Mobile Responsiveness</h1>
        <p className="changelog-content-text ">
          We have started to make the application mobile-responsive and plan to
          make it better and better with each update :)
        </p>
      </div>
    </div>
  );
}

export default Date20240825;
