import React from "react";
import Img2 from "../../../assets/imgs/2024-Sep-15/assignmentpage.jpg";
import Img3 from "../../../assets/imgs/2024-Sep-15/preferences.jpg";

function Date20240815() {
  return (
    <div className="changelog-container" id="2024-Sep-15">
      <div className="changelog-date">
        <p>September 15th, 2024</p>
      </div>
      <div className="changelog-content">
        <h1 className="changelog-big-title">
          Individual assignments and settings (v0.2.0)
        </h1>
        <img src={Img2} alt="" loading="lazy" />
        <h1 className="changelog-mini-title">Individual assignment page</h1>
        <p className="changelog-content-text ">
          We have now added the ability to open your assignments into their own
          page. You can now do things like:
        </p>
        <ul className="changelog-content-text list-margin">
          <li>
            Keep track of any important links, jot down quick notes related to
            the assignment
          </li>
          <li>Upload and quickly access your assignment's PDF file</li>
          <li>
            Breakdown your assignment into sub-tasks and schedule them into your
            workflow
          </li>
        </ul>
        <img src={Img3} alt="" loading="lazy" />
        <h1 className="changelog-mini-title">User settings and preferences</h1>
        <p className="changelog-content-text ">
          We have also added a new settings page which can be found in the main
          dropdown when clicking on your name in the top left of the navigation
          menu. You can see/change things like:
        </p>
        <ul className="changelog-content-text list-margin">
          <li>Reset password, change email, set first and last name</li>
          <li>You can now delete your account and all of your data</li>
          <li>See your storage usage</li>
          <li>Customize certain app behaviour</li>
        </ul>
        <h1 className="changelog-mini-title">General fixes and improvements</h1>
        <ul className="changelog-content-text list-margin">
          <li>Fixed errors with file uploading</li>
          <li>
            Recent notes on the home page now display in their proper markdown
          </li>
          <li>
            Fixed issue with calendar font failing with different languages
          </li>
          <li>Fixed black screen issue on the flashcards page</li>
        </ul>
      </div>
    </div>
  );
}

export default Date20240815;
