import React, { useState } from "react";
import "./Join.css";
import { supabase } from "../supabaseFiles/supabaseClient";

import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function Join({ alert, setAlert }) {
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const { error } = await supabase
        .from("waitlist")
        .insert({ first_name: e.target[0].value, email: e.target[1].value });
      if (!error) {
        setSubmitted(true);
      } else {
        setAlert(!alert);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="join-wrapper j-page">
      <motion.p
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.25 }}
        className="join-main-txt"
      >
        Don't miss out, request your invite.
      </motion.p>
      <motion.p
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.5 }}
        className="join-sub"
      >
        Request to be one of the first to explore Notibly. Limited to just{" "}
        <span>500</span> openings - don't worry, it's <span>free.</span>
      </motion.p>
      {!submitted ? (
        <motion.form
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.7 }}
          action="submit"
          className="waitlist-input"
          onSubmit={handleFormSubmit}
        >
          <input
            type="text"
            className="email-in"
            placeholder="First Name"
            required
          />
          <input
            type="email"
            className="email-in"
            placeholder="Email"
            required
          />
          <button type="submit" className="join-btn">
            Request Invitation
          </button>
        </motion.form>
      ) : (
        <div className="submitted-wrapper">
          <p className="submitted-txt">
            Request sent successfully! We'll be in touch!
          </p>
        </div>
      )}
      <div className="j-space"></div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 1 }}
        className="footer-wrapper"
      >
        <div className="footer-col">
          <p className="footer-header">Connect with us</p>
          <p
            className="footer-link"
            onClick={() =>
              window.open(
                "https://www.twitter.com/notiblyapp",
                "_blank",
                "noreferrer"
              )
            }
          >
            Twitter
          </p>
          <p
            className="footer-link"
            onClick={() =>
              window.open(
                "https://www.instagram.com/notibly",
                "_blank",
                "noreferrer"
              )
            }
          >
            Instagram
          </p>
          <p className="footer-link">Linkedin</p>
        </div>
        <div className="footer-col">
          <p className="footer-header">Notibly</p>
          <p className="footer-link">Features</p>
          <p className="footer-link">Pricing</p>
          <p className="footer-link">FAQ</p>
          <p
            className="footer-link"
            onClick={() => {
              navigate("/changelog");
              window.scrollTo(0, 0);
            }}
          >
            Changelog
          </p>
          <p
            className="footer-link"
            onClick={() =>
              window.open(
                "https://notibly.featurebase.app/?b=66b51f108e316055502c298e",
                "_blank"
              )
            }
          >
            Request a feature
          </p>
        </div>
        <div className="footer-col">
          <p className="footer-header">Company</p>
          <p className="footer-link">Our Mission (Coming soon)</p>
          <p className="footer-link">Careers (Coming soon)</p>
        </div>

        <div className="j-space"></div>
        <div className="footer-col">
          <p className="footer-header">Legal</p>
          <p
            className="footer-link"
            onClick={() => {
              navigate("/terms");
              window.scrollTo(0, 0);
            }}
          >
            Terms of service
          </p>
          <p
            className="footer-link"
            onClick={() => {
              navigate("/privacy");
              window.scrollTo(0, 0);
            }}
          >
            Privacy Policy
          </p>
          <p
            className="footer-link"
            onClick={() => {
              navigate("/disclaimer");
              window.scrollTo(0, 0);
            }}
          >
            Disclaimer
          </p>
        </div>
      </motion.div>
      <p className="copyright-txt">
        © {new Date().getFullYear()} Notibly - All Rights Reserved.
      </p>
    </div>
  );
}

export default Join;
