import React, { useState } from "react";
import FAQ from "../components/FAQ";
import "./Features.css";

import { motion } from "framer-motion";

function FAQPage() {
  const [faqs, setFaqs] = useState([
    {
      question: "What makes Notibly different from other productivity tools?",
      answer:
        "Notibly is tailored specifically for students, incorporating features that address their unique challenges in organizing courses, assignments, and notes. It's designed to streamline the academic experience and boost productivity.",
      open: false,
    },
    {
      question: " Is my data secure on Notibly?",
      answer:
        " Yes, we take your privacy seriously. Notibly employs robust security measures to ensure the confidentiality and integrity of your data. We use encryption and follow industry best practices to safeguard your information.",
      open: false,
    },
    {
      question: "How does Notibly help me stay on top of deadlines?",
      answer:
        "Notibly features a dynamic task management system that provides reminders and notifications for upcoming assignments, exams, and deadlines. It helps you prioritize tasks, ensuring nothing falls through the cracks.",
      open: false,
    },
    {
      question:
        "Can I collaborate with classmates on group projects using Notibly?",
      answer:
        "While the current version focuses on individual productivity, we are actively working on collaborative features. Stay tuned for updates as we enhance Notibly to meet your collaborative needs.",
      open: false,
    },
    {
      question: "Can I customize Notibly to fit my preferences?",
      answer:
        "Yes, we understand that personalization is key. Notibly allows you to customize all aspects of the dashboard and settings to suit your preferences, providing a tailored experience.",
      open: false,
    },
    {
      question: " Is Notibly free, or are there subscription plans?",
      answer:
        "We offer a free version with essential features, and for users looking for advanced functionalities, we have subscription plans available. Check out our pricing page for more details on premium features and subscription options.",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  return (
    <div className="faq-wrapper">
      <div className="test-title-wrapper">
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.25 }}
          className="features-txt"
        >
          Have questions? We have answers.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
          className="features-sub"
        >
          Here you can find answers to common questions. Still have a question?
          Contact us.
        </motion.p>
      </div>
      <motion.div
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.7 }}
        className="faqs"
      >
        {faqs.map((faq, index) => (
          <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </motion.div>
    </div>
  );
}

export default FAQPage;
