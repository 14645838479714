import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { PartyPopper } from "lucide-react";

function Navbar() {
  const navigate = useNavigate();

  const navigateAndScroll = (id) => {
    navigate("/", { state: { scrollToId: id } });
  };

  const openLogin = () => {
    window.open("https://my.notibly.app/login", "_blank");
  };

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.75 }}
      className="nav-wrapper"
    >
      <div className="alpha-banner">
        <PartyPopper height="14px" width="14px" />
        <p className="banner-txt">
          We're excited to announce our official alpha release! Sign up now for
          an exclusive invite and early access.
        </p>
      </div>
      <div className="nav-container">
        <div className="logo-wrapper">
          <div
            className="logo-container"
            onClick={() => navigateAndScroll("home")}
          >
            <p className="logo-txt">notibly</p>
          </div>
        </div>
        <div className="nav-link-wrapper">
          <p
            className="nav-txt features"
            onClick={() => navigateAndScroll("home")}
          >
            Home
          </p>
          <p
            className="nav-txt features"
            onClick={() => navigateAndScroll("features")}
          >
            Features
          </p>
          <p
            className="nav-txt features"
            onClick={() => navigateAndScroll("pricing")}
          >
            Pricing
          </p>
          <p
            className="nav-txt features"
            onClick={() => navigateAndScroll("faq")}
          >
            FAQ
          </p>
          <p
            className="nav-txt features"
            onClick={() => {
              navigate("/changelog");
              window.scrollTo(0, 0);
            }}
          >
            Changelog
          </p>
        </div>
        <div className="button-wrapper">
          <button
            className="sign-up-btn"
            onClick={() => navigateAndScroll("join")}
          >
            Request invite
          </button>
          <button className="login-btn" onClick={openLogin}>
            Login
          </button>
        </div>
      </div>
    </motion.nav>
  );
}

export default Navbar;
