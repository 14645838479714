import Img1 from "../../../assets/imgs/2024-Aug-31/1.jpg";

function Date20240731() {
  return (
    <div className="changelog-container" id="2024-Aug-31">
      <div className="changelog-date">
        <p>August 31st, 2024</p>
      </div>
      <div className="changelog-content">
        <h1 className="changelog-big-title"> Alpha Launch (v0.1.0) </h1>
        <p className="changelog-content-text">
          We’re excited to announce the release of Notibly’s alpha version! 🎉
          This is our first public release, and we’ve started with a few
          features to help you stay organized and productive. Here’s what’s
          included in v.0.1.0:
        </p>
        <h1 className="changelog-mini-title"> New Features: </h1>
        <ul className="changelog-content-text list-margin">
          <li>
            <strong>Note Taking</strong>: Capture and organize your notes all in
            one place, with easy access across all your courses.
          </li>
          <li>
            <strong>Course Organization</strong>: Keep track of all your
            classes, assignments, tasks, and materials within dedicated course
            sections.
          </li>
          <li>
            <strong>Calendar</strong>: Manage your schedule with an integrated
            calendar that syncs with your courses and assignments.
          </li>
          <li>
            <strong>Task Management</strong>: Stay on top of your to-dos with
            our Agenda page, helping you prioritize and plan out your days.
          </li>
          <li>
            <strong>Assignment Organization</strong>: Easily organize and manage
            all your assignments, with due dates.
          </li>
          <li>
            <strong>AI Flashcard Generation</strong>: Instantly convert your
            notes into flashcards using our AI-powered tool, designed to help
            you study more effectively. (This will likely need refinement as we
            go to improve its capability)
          </li>
        </ul>
        <h1 className="changelog-mini-title"> Things that are coming: </h1>
        <p className="changelog-content-text">
          This is literally just the beginning for us, we just wanted to finally
          get something out into your hands so we have something to build upon.
          We have a whole bunch of new features planned for our future releases,
          such as more AI tools, integrations, and general user experience
          improvements.
        </p>
        <p className="changelog-content-text">
          Here’s what you can expect within the next couple weeks:
        </p>
        <ul className="changelog-content-text list-margin">
          <li>
            <strong>File Uploads</strong>: Although we’re still working on tools
            to scan and summarize PDFs and slideshows, we still want to give you
            the ability to upload and store all of your course files for optimal
            organization and easy reference.
          </li>
          <li>
            <strong>Updated Event UX</strong>: We’re going to be making some
            modifications to how you create events. Right now it requires a
            little bit too much clicking for our liking, so we’ll be adapting it
            to a Google Calendar style.
          </li>
        </ul>
        <img src={Img1} alt="" loading="lazy" />
        <ul className="changelog-content-text list-margin">
          <li>
            <strong>Note taking improvements </strong>Additionally, we want to
            expand our editor to allow for features such as: formatted code
            blocks and AI writing. We also want to expand to allow linking
            between notes, to make easy connections and references between other
            notes you may have. spoiler: this may include our take on a mind
            model system… ;)
          </li>
        </ul>
        <p className="changelog-content-text">
          This is just a teenie tiny glimpse of what's ahead, and we're thrilled
          to keep building our vision with your support. Your feedback means the
          world to us—this wouldn't be possible without it!
          <br />
          Thank you so much for all of the support, and if you’re in school,
          best of luck with your semester :)
        </p>
      </div>
    </div>
  );
}

export default Date20240731;
