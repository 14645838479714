import React from "react";
import "./FeaturePage.css";
import problems from "../assets/problems.svg";
import { motion } from "framer-motion";

function FeaturePageThree() {
  return (
    <div className="feature-page-wrapper">
      <div className="f-text-wrapper">
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.25 }}
          className="f-title"
        >
          Generate infinite practice problems.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
          className="f-sub-txt"
        >
          Notibly empowers you to generate an endless array of practice
          problems, ensuring a thorough mastery of key concepts.
        </motion.p>
      </div>
      <div className="f-space"></div>
      <motion.div
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.6 }}
        className="f-image-wrapper problems-img"
      >
        <img className="prob-image" src={problems} alt="flashcard generation" />
      </motion.div>
    </div>
  );
}

export default FeaturePageThree;
