import React from "react";
import "./FeaturePage.css";
import flash from "../assets/flash.svg";
import { motion } from "framer-motion";
function FeaturePageTwo() {
  return (
    <div className="feature-page-wrapper">
      <div className="f-text-wrapper">
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.25 }}
          className="f-title"
        >
          Generate study cards, instantly.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
          className="f-sub-txt"
        >
          Say goodbye to manual flashcard creation. Just pick a course, and let
          Notibly generate your study cards for you.
        </motion.p>
      </div>
      <div className="f-space"></div>
      <motion.div
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.6 }}
        className="f-image-wrapper flash-img"
      >
        <img src={flash} alt="flashcard generation" />
      </motion.div>
    </div>
  );
}

export default FeaturePageTwo;
