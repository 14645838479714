import React from "react";
import summary from "../../../assets/Nov17/Summary.svg";
import inbox from "../../../assets/Nov17/Inbox.svg";
import workboard from "../../../assets/Nov17/Workboard.svg";

function Date20241117() {
  return (
    <div className="changelog-container" id="2024-Nov-04">
      <div className="changelog-date">
        <p>November 17th, 2024</p>
      </div>
      <div className="changelog-content">
        <h1 className="changelog-big-title">Note summarization</h1>
        <img src={summary} alt="" loading="lazy" />
        <p className="changelog-content-text">
          Note summarization has been added so you can create summaries for your
          notes on the fly.
          <br />
          <br />
          Now instead of reading long notes, you can simply summarize them in
          one click to get them in a more digestible format.
          <br />
          <br />
          Limited to 5 uses per month for free users, and unlimited for pro
          users.
          <br />
          <br />
          <i>Please note: This feature is still undergoing testing.</i>
        </p>
        <h1 className="changelog-big-title">Assignment notifications</h1>
        <img src={inbox} alt="" loading="lazy" />
        <p className="changelog-content-text">
          We have also added a new inbox page where you will be able to get
          notifications on upcoming assignment due dates.
          <br />
          <br />
          Right now, it&apos;s set to notify you when an assignment is 1 week
          out, 3 days out and 1 day out.
          <br />
          <br />
          We are working to add the option to change this preference, and also
          choose what to be notified on. We are also working to add an opt-in
          for email notifications as well.
        </p>
        <h1 className="changelog-big-title">Workboard filters</h1>
        <img src={workboard} alt="" loading="lazy" />
        <p className="changelog-content-text">
          We&apos;ve combined the assignments and work board page into one view,
          with a toggle to go back and forth depending on what format you want
          to see.
          <br />
          <br />
          We have also added filters to the page to allow for you to filter for
          exactly what you want to see.
        </p>
      </div>
    </div>
  );
}

export default Date20241117;
