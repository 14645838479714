import React from "react";
import community from "../../../assets/Nov08/communitytab.jpg";
import avatar from "../../../assets/Nov08/Avatar.jpg";
import move from "../../../assets/Nov08/movenotes.jpg";
import share from "../../../assets/Nov08/shareablenotes.jpg";

function Date20241104() {
  return (
    <div className="changelog-container" id="2024-Nov-04">
      <div className="changelog-date">
        <p>November 4th, 2024</p>
      </div>
      <div className="changelog-content">
        <h1 className="changelog-big-title">
          Community Tab and Feature Updates (v0.4.0)
        </h1>
        <img src={community} alt="" loading="lazy" />
        <h1 className="changelog-mini-title">Community Tab</h1>
        <p className="changelog-content-text">
          Introducing the Community section! Discover notes from users around
          the world. You can contribute by publishing your notes and toggling
          them for community visibility.
        </p>
        <img src={avatar} alt="" loading="lazy" />
        <h1 className="changelog-mini-title">Profile Settings</h1>
        <p className="changelog-content-text">
          You can now set profile photos and usernames! Profile photos are
          publicly viewable across the app, and usernames will be shown on
          shared notes or comments. Claim your username while it’s still
          available.
        </p>
        <img src={move} alt="" loading="lazy" />
        <h1 className="changelog-mini-title">Editor Improvements</h1>
        <p className="changelog-content-text">
          Upload images and files directly in the editor! Files added in the
          editor are public, so anyone with the file URL can access it.
          Additionally, you can now move notes between courses.
        </p>
        <img src={share} alt="" loading="lazy" />
        <h1 className="changelog-mini-title">Shareable Notes</h1>
        <p className="changelog-content-text">
          Notes are now shareable! Find the share button in the top-right corner
          of the screen to publish your note and share the link with others.
          Anyone with the link can view the note.
        </p>

        <h1 className="changelog-mini-title">General Fixes</h1>
        <ul className="changelog-content-text list-margin">
          <li>Resolved an issue with storage calculations</li>
          <li>Fixed icon sizing in the notes menu</li>
          <li>Improved editor UI with larger spacing and color adjustments</li>
          <li>
            Fixed bugs on the assignments page for task and description saving
          </li>
          <li>Enhanced formatting for note titles</li>
          <li>Notes in folders now sorted by creation date</li>
        </ul>

        <h1 className="changelog-mini-title">Upcoming Features</h1>
        <p className="changelog-content-text">
          We have a ton in the pipeline currently. Some of which are:
        </p>
        <ul className="changelog-content-text list-margin">
          <li>
            <span className="font-['Medium']">Inbox</span> for real-time
            notifications and custom reminders
          </li>
          <li>
            <span className="font-['Medium']">Note Tabbing</span> to support
            multiple tabs open at once
          </li>
          <li>
            <span className="font-['Medium']">AI Note Suggestions</span> based
            on assignment content (retrieve relevant notes for assignments)
          </li>
          <li>
            <span className="font-['Medium']">
              Note Summarizations and Review Quizzes
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Date20241104;
