import React from "react";

function Date20241014() {
  return (
    <div className="changelog-container" id="2024-Sep-15">
      <div className="changelog-date">
        <p>October 14th, 2024</p>
      </div>
      <div className="changelog-content">
        <h1 className="changelog-big-title">
          Pro plans, code blocks and future features (v0.3.0)
        </h1>
        <h1 className="changelog-mini-title">Pro Plans Now Available</h1>
        <p className="changelog-content-text ">
          We've finally released pro plans with a limited launch price of just
          $5/month (58% off) or $3/month for annual subscribers (75% off). As a
          special thank you for supporting us in these early stages, you'll lock
          in this price for life—even as we continue to roll out more powerful
          features and eventually increase the price.
        </p>

        <h1 className="changelog-mini-title">Flashcard Enhancements</h1>
        <p className="changelog-content-text ">
          You can now manually create flashcard sets! While the flashcard
          feature is now exclusive to Pro users, we are working on introducing a
          free tier where users can enjoy 2 free AI-generated flashcard sets per
          month. <br />
          <br /> We have plans to improve this feature heavily by adding in a
          proper testing mode, spaced repitition and fill in the blank cards.
        </p>

        <h1 className="changelog-mini-title">Code block support</h1>
        <ul className="changelog-content-text list-margin">
          <li>
            We’ve added code block support in your notes, allowing you to
            seamlessly format and organize code snippets for better clarity and
            structure.
          </li>
        </ul>

        <h1 className="changelog-mini-title">Exciting Upcoming Features</h1>
        <p className="changelog-content-text ">
          We’re hard at work on a few major features:
        </p>
        <ul className="changelog-content-text list-margin">
          <li>
            <span className="font-['Medium']">Google Calendar Integration</span>{" "}
            – Soon, all users (free and Pro) will be able to sync their tasks
            and events directly with Google Calendar.
          </li>
          <li>
            <span className="font-['Medium']">The New Library Tab</span> – A
            dedicated space for uploading and organizing your readings and
            research papers. The Library will offer AI-powered tools to
            summarize content, generate quizzes, and even provide audio
            narration to help you stay on top of your readings.
          </li>
          <li>
            <span className="font-['Medium']">Math Equation Support</span> – We
            are planning to add the ability to add LaTeX code to display math
            equations in your notes.
          </li>
          <li>
            <span className="font-['Medium']">Agenda Page Rework</span> – We
            also have plans to revamp the agenda page to create a more intuitive
            task planning interface, with the ability to timebox tasks directly
            and much more.
          </li>
        </ul>

        <h1 className="changelog-mini-title">New Discord Community</h1>
        <p className="changelog-content-text ">
          We're also planning on creating our own discord community where users
          can collaborate, provide feature requests, network, join study groups
          and much more.
          <br />
          <br />
          We want to create a space where you can not only enhance your academic
          productivity but also build connections with a supportive community of
          students who are navigating the same challenges. Stay tuned for the
          official launch – we can't wait to see you there!
        </p>
      </div>
    </div>
  );
}

export default Date20241014;
