import React from "react";
import "./Terms.css";

function Disclaimer() {
  return (
    <div className="legal-wrapper">
      <h1 className="legal-title">Privacy policy</h1>
      <p className="legal-date">
        <span className="bold">Effective date</span>: August 31st, 2024
      </p>
      <div className="content-wrapper">
        <p className="title">
          <b>WEBSITE DISCLAIMER</b>
        </p>
        <p className="space">
          The information provided by <b>Notibly</b> (“Company”, “we”, “our”,
          “us”) on <b>my.notibly.app</b> (the “Site”) is for general
          informational purposes only. All information on the Site is provided
          in good faith, however we make no representation or warranty of any
          kind, express or implied, regarding the accuracy, adequacy, validity,
          reliability, availability, or completeness of any information on the
          Site.
        </p>
        <p className="space">
          UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS
          OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR
          RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE
          AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN
          RISK.
        </p>
        <p className="space">
          <b>EXTERNAL LINKS DISCLAIMER</b>
        </p>
        <p className="space">
          The Site may contain (or you may be sent through the Site) links to
          other websites or content belonging to or originating from third
          parties or links to websites and features. Such external links are not
          investigated, monitored, or checked for accuracy, adequacy, validity,
          reliability, availability or completeness by us.
        </p>
        <p className="space">
          For example, the outlined{" "}
          <a href="https://policymaker.io/disclaimer/">Disclaimer</a> has been
          created using <a href="https://policymaker.io/">PolicyMaker.io</a>, a
          free web application for generating high-quality legal documents.
          PolicyMaker’s{" "}
          <a href="https://policymaker.io/disclaimer/">disclaimer generator</a>{" "}
          is an easy-to-use tool for creating an excellent sample Disclaimer
          template for a website, blog, eCommerce store or app.
        </p>
        <p className="space">
          WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR
          THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY
          WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN
          ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY
          WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
          THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
        </p>
        <p className="space">
          <b>ERRORS AND OMISSIONS DISCLAIMER</b>
        </p>
        <p className="space">
          While we have made every attempt to ensure that the information
          contained in this site has been obtained from reliable sources,
          Notibly is not responsible for any errors or omissions or for the
          results obtained from the use of this information. All information in
          this site is provided “as is”, with no guarantee of completeness,
          accuracy, timeliness or of the results obtained from the use of this
          information, and without warranty of any kind, express or implied,
          including, but not limited to warranties of performance,
          merchantability, and fitness for a particular purpose.
        </p>{" "}
        <p className="space">
          In no event will Notibly, its related partnerships or corporations, or
          the partners, agents or employees thereof be liable to you or anyone
          else for any decision made or action taken in reliance on the
          information in this Site or for any consequential, special or similar
          damages, even if advised of the possibility of such damages.
        </p>
        <p className="space">
          <b>GUEST CONTRIBUTORS DISCLAIMER</b>
        </p>
        <p className="space">
          This Site may include content from guest contributors and any views or
          opinions expressed in such posts are personal and do not represent
          those of Notibly or any of its staff or affiliates unless explicitly
          stated.
        </p>
        <p className="space">
          <b>LOGOS AND TRADEMARKS DISCLAIMER</b>
        </p>
        <p className="space">
          All logos and trademarks of third parties referenced on my.notibly.app
          are the trademarks and logos of their respective owners. Any inclusion
          of such trademarks or logos does not imply or constitute any approval,
          endorsement or sponsorship of Notibly by such owners.
        </p>
        <p className="space">
          <b>CONTACT US</b>
        </p>
        <p className="space">
          Should you have any feedback, comments, requests for technical support
          or other inquiries, please contact us by email:{" "}
          <b>hello@notibly.app</b>.
        </p>
      </div>
    </div>
  );
}

export default Disclaimer;
