import React from "react";
import "./home.css";
import hero from "../assets/landingmock.png";
import { motion } from "framer-motion";

function Home() {
  const handleClickScroll = () => {
    const el = document.getElementById("join");
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="home-wrapper">
      <div className="landing-section">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
          className="left"
        >
          <div>
            <p className="mobile-landing">
              All of your classes organized, all in one place.
            </p>
          </div>
          <p className="landing-main">
            All of your classes
            <br />
            organized, all in one place.
          </p>
          <p className="landing-sub">
            Meet Notibly — A comprehensive school productivity app crafted by
            students, for students.{" "}
          </p>

          <button
            type=""
            className="join-btn landing-btn"
            onClick={handleClickScroll}
          >
            Request Invite
          </button>

          <div className="h-space"></div>
          <div className="how-wrapper">
            <p className="how-txt">Learn more</p>
            <img
              width="16"
              height="16"
              src="https://img.icons8.com/material-rounded/24/ffffff/chevron-down.png"
              alt="chevron-down"
            />
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
          className="right"
        >
          <img src={hero} alt="Dashboard" />
        </motion.div>
      </div>
    </div>
  );
}

export default Home;
