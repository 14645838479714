import React from "react";
import "./FeaturePage.css";
import tasks from "../assets/tasks.svg";
import { motion } from "framer-motion";

function FeaturePageOne() {
  return (
    <div className="feature-page-wrapper">
      <div className="f-text-wrapper">
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.25 }}
          className="f-title"
        >
          Let Notibly think for you.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
          className="f-sub-txt"
        >
          Automatically generate your tasks for the day so you can spend less
          time deciding what to work on and more time getting things done.
        </motion.p>
      </div>
      <motion.div
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.6 }}
        className="f-image-wrapper"
      >
        <img src={tasks} alt="task generation" />
      </motion.div>
    </div>
  );
}

export default FeaturePageOne;
