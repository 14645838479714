import React from "react";
import "./Testimonials.css";
import "./Features.css";
import { motion } from "framer-motion";

function Testimonials() {
  return (
    <div className="test-wrapper">
      <div className="test-title-wrapper">
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.25 }}
          className="features-txt"
        >
          But don't just take our word for it.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
          className="features-sub"
        >
          Notibly has helped numerous students transform their academic journey.
        </motion.p>
      </div>
      <div className="reviews-wrapper">
        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.6 }}
          className="review"
        >
          <div className="r-top">
            <p className="r-name">Sarah</p>
            <p className="r-title">College Sophmore</p>
          </div>
          <div className="r-body">
            <p className="r-txt">
              I don't normally leave reviews but after trying Notibly out I can
              definitely promise this is a must for every student. I've finally
              been able to feel on track for all of my classes for once.
            </p>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.8 }}
          className="review"
        >
          <div className="r-top">
            <p className="r-name">Alex</p>
            <p className="r-title">Graduate Student</p>
          </div>
          <div className="r-body">
            <p className="r-txt">
              As a researcher with a ton on my plate, Notibly has really helped
              me stay organized. I always know where my notes are, and never
              miss deadlines. It's literally like having a personal assistant
              for school.
            </p>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 1 }}
          className="review"
        >
          <div className="r-top">
            <p className="r-name">Chris</p>
            <p className="r-title">Engineering Student</p>
          </div>
          <div className="r-body">
            <p className="r-txt">
              I'm in my last year and I definitely could have used Notibly
              earlier. I only got to try out the early access version but just
              that alone made my life so much easier. Highly recommend!
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Testimonials;
